import { Search2Icon } from '@chakra-ui/icons'
import { Input, IconButton, HStack, Box, Text, Image, Link, Menu, MenuList, MenuItem, InputGroup, InputRightElement } from '@chakra-ui/react'
import axios from 'axios'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'


const defaultAvatar = (tag) => {
  if (tag.substr(3,4) === '0' || tag.substr(3,4) === '5') return 'https://cdn.discordapp.com/embed/avatars/0.png'
  if (tag.substr(3,4) === '1' || tag.substr(3,4) === '6') return 'https://cdn.discordapp.com/embed/avatars/1.png'
  if (tag.substr(3,4) === '2' || tag.substr(3,4) === '7') return 'https://cdn.discordapp.com/embed/avatars/2.png'
  if (tag.substr(3,4) === '3' || tag.substr(3,4) === '8') return 'https://cdn.discordapp.com/embed/avatars/3.png'
  if (tag.substr(3,4) === '4' || tag.substr(3,4) === '9') return 'https://cdn.discordapp.com/embed/avatars/5.png'
}

export const SearchComponent = ({ size }) => {
  const router = useRouter();
  const [user, setUser] = useState({})
  const [menu, setMenu] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (router.query.id === e.target.id.value) return;
    if (!e.target.id.value.length) return;
    if (e.target.id.value.length < 17 || e.target.id.value.length > 19) return;

    router.push({
      pathname: `/check/[id]`,
      query: { id: e.target.id.value }
    })
  }

  const handleChange = async (e) => {
    setUser({})

    if (e.target.value.length < 17 || e.target.value.length > 19) return;

    setTimeout(() => {
      axios.get('/api/check', {
        params: {
          id: e.target.value
        }
      }).then(data => {
        setUser(data.data.user)
        setMenu(true)
      }).catch(() => {})
    }, 700);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputGroup size={size}>
          <Input
            placeholder='Search user by ID'
            id="id"
            size={size}
            maxLength={19}
            onChange={handleChange}
            defaultValue={router.query.id}
            onKeyPress={(event) => {
              if (event.key === 'Enter') return;
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <InputRightElement zIndex={1}>
            <IconButton
              mr={2}
              size={size}
              icon={<Search2Icon/>}
              variant="ghost"
              type="submit"
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
            />
          </InputRightElement>
        </InputGroup>
      </form>
      
      {user?.username && (
        <NextLink href={`/check/${user.id}`} passHref>
          <Link>
            <Box pos="absolute" display={menu ? 'block' : 'hidden'}>
              <Menu isOpen={menu} onClose={() => setMenu(false)} size={size}>
                <MenuList 
                  minW={size === 'lg' ? "16.3rem" : '15rem'}
                >
                  <MenuItem>
                    <HStack direction="row">
                      <Image
                        alt=""
                        w="29px" 
                        rounded="full" 
                        src={user.avatar ? `//cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=32` : defaultAvatar(user.discriminator)}
                      />

                      <Text noOfLines={1}>
                        {user.username}
                        <small style={{ fontSize: 'x-small', fontWeight: 'normal' }}>#{user.discriminator}</small>
                      </Text>
                    </HStack>
                  </MenuItem>
                </MenuList>      
              </Menu>
            </Box>
          </Link>
        </NextLink>
      )}
    </>
  );
}
