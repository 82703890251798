import { SEOComponent } from "../components/SEO";
import { sql_query } from "../lib/postgres";
import { prisma } from "../lib/prisma";
import { SearchComponent } from "../components/Search";
import NextLink from "next/link";
import {
  Box,
  Heading,
  Text,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Container,
  Spacer,
  useColorModeValue,
  Wrap,
  Image,
  HStack,
  VStack,
  Divider,
  Button,
  Center,
  Tooltip
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getUserCache } from "../lib/cache";
import { motion } from "framer-motion";
dayjs.extend(relativeTime);
import { useEffect, useState } from "react";
import PromoComponent from "~/components/PromoComponent";

export default function Home({ reports, users }) {
  const [reportAmount, setReportAmount] = useState(6);

  const defaultAvatar = (tag) => {
    let num = 0;
    if (tag?.substr(3, 4) === "0" || tag?.substr(3, 4) === "5") num = 0;
    else if (tag?.substr(3, 4) === "1" || tag?.substr(3, 4) === "6") num = 1;
    else if (tag?.substr(3, 4) === "2" || tag?.substr(3, 4) === "7") num = 2;
    else if (tag?.substr(3, 4) === "3" || tag?.substr(3, 4) === "8") num = 3;
    else if (tag?.substr(3, 4) === "4" || tag?.substr(3, 4) === "9") num = 4;
    return `https://cdn.discordapp.com/embed/avatars/${num}.png`;
  };

  const defaultBanner = (tag) => {
    let hex = "555555";
    if (tag?.substr(3, 4) === "0" || tag?.substr(3, 4) === "5") hex = "43b581"; // green
    else if (tag?.substr(3, 4) === "1" || tag?.substr(3, 4) === "6") hex = "faa61a"; // yellow
    else if (tag?.substr(3, 4) === "2" || tag?.substr(3, 4) === "7") hex = "f04747"; // red
    else if (tag?.substr(3, 4) === "3" || tag?.substr(3, 4) === "8") hex = "5865f2"; // blue
    else if (tag?.substr(3, 4) === "4" || tag?.substr(3, 4) === "9") hex = "f47fff"; // pink
    return `#${hex}`;
  };
  
  const iconBorder = useColorModeValue('#ffffff', '#121212')
  const timestampColor = useColorModeValue("gray.500", "gray.400")

  return (
    <>
      <SEOComponent description="Welcome to Dangercord, the go-to place for making Discord a safer place." banner="https://cdn.discordapp.com/attachments/1061318957870620692/1066709924714913832/image_18.png" />

      <Box
        bg={useColorModeValue("gray.100", "#1E1E1E")}
        p={{ base: "2", md: "6" }}
        pb={{ base: "6" }}
        mt={"-2rem"}
      >
        <Container maxW="1250px">
          <Wrap>
          <Box as={motion.div} animate={{ x: [-800, 100, 0] }}>
              <Image
                src="/logo.png"
                alt="Dangercord Logo"
                height="65px"
                mr="2"
                filter={useColorModeValue("invert(1)", "none")}
                onClick={() => window.open("https://i.imgur.com/GnKLV8N.png", "_blank")}
                cursor={"help"}
              />

              <Text
                fontSize="xl"
                fontWeight="normal"
                color={useColorModeValue("gray.700", "gray.200")}
                mt={"2"}
              >
                Welcome to Dangercord!
              </Text>
            </Box>

            <Spacer />

            <Box
              pr={{ lg: 24 }}
              as={motion.div}
              animate={{ x: [800, -100, 0] }}
            >
              <Text color={useColorModeValue("gray.700", "gray.200")}>
                Search for a user:
              </Text>
              <SearchComponent size="lg" />
            </Box>
          </Wrap>
        </Container>
      </Box>

      <Container maxW="1250px" mt={6}>
        <Box>
          <PromoComponent />

          <Heading size="lg" mb={1}>
            Latest reports
          </Heading>
          <SimpleGrid gap={6} columns={{ base: 1, md: 3 }} mt={5}>
            {reports.map((report, i) => {
              if (i >= reportAmount) return;

              const user = users.find((user) => user.id === report.reported);
              if (!user) return;

              return (
                <Box
                  as={motion.div}
                  initial={{ y: 20 }}
                  animate={{ y: 0 }}
                  key={report.created_at}
                >
                  <LinkBox
                    as="article"
                    maxW={{ base: "full", md: "sm" }}
                    borderWidth="1px"
                    rounded="md"
                    transition={"all 0.1s ease-in-out"}
                    _hover={{
                      border: "1px",
                      borderColor: "orange.500",
                    }}
                  >
                    {user.banner && (
                      <Image
                        alt=""
                        w="full"
                        h="20"
                        roundedTop="md"
                        src={
                          user.banner
                            ? `//cdn.discordapp.com/banners/${user.id}/${user.banner}?size=512`
                            : defaultAvatar(user.discriminator)
                        }
                        objectFit="cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultAvatar(user.discriminator);
                        }}
                      />
                    ) || (
                      <Box
                        h="20"
                        w="full"
                        roundedTop="md"
                        bg={defaultBanner(user.discriminator)}
                      />
                    )}
                    <Box p={4}>
                      <Heading size="md" my={2}>
                        <NextLink href={`/check/${user.id}`} passHref>
                          <LinkOverlay>
                            <VStack direction="row">
                              <Image
                                alt=""
                                w="65px"
                                rounded="full"
                                marginTop={-14}
                                marginBottom={-1}
                                src={
                                  user.avatar
                                    ? `//cdn.discordapp.com/avatars/${user.id}/${user.avatar}?size=512`
                                    : defaultAvatar(user.discriminator)
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = defaultAvatar(user.discriminator);
                                }}
                                border={`5px solid ${iconBorder}`}
                                bg={iconBorder}
                              />

                              <Text noOfLines={1}>
                                {user.username}
                                <small
                                  style={{
                                    fontSize: "small",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {user.discriminator === "0" ? `` : `#${user.discriminator}`}
                                </small>
                              </Text>
                            </VStack>
                          </LinkOverlay>
                        </NextLink>
                      </Heading>

                      <Text noOfLines={1}>{report.reason}</Text>

                      <Box
                        as="time"
                        dateTime={new Date(Number(report.created_at))}
                      >
                        <HStack>
                          <Text style={{ fontSize: "small" }} color={timestampColor}>
                            {dayjs(Number(report.created_at)).fromNow()}{" "}
                          </Text>

                          {user?.blacklisted && (
                            <>
                              <Divider orientation="vertical" h={4} />

                              <Text fontSize="smaller" color="red.400">
                                Blacklisted User
                              </Text>
                            </>
                          )}
                        </HStack>
                      </Box>
                    </Box>
                  </LinkBox>
                </Box>
              );
            })}
          </SimpleGrid>
          <Center>
            <Button
              onClick={() => {
                if (reportAmount > 30) return;
                setReportAmount(reportAmount + 6);
              }}
              disabled={reportAmount >= 30}
              mt={3}
              w="10rem"
            >
              Show more
            </Button>
          </Center>
        </Box>
      </Container>
    </>
  );
}

export async function getStaticProps() {
  try {
    const reports = await sql_query(`
      SELECT reported, reason, created_at 
      FROM reports 
      WHERE hidden = false AND reported NOT IN ('643945264868098049', '669627189624307712') AND bulk_id IS NULL
      ORDER BY created_at DESC
      LIMIT 30;
    `);

    // mapping not working for the prisma response
    // const reports = await prisma.reports.findMany({
    //   take: 30,
    //   selected: {
    //     reported: true,
    //     reason: true,
    //     created_at: true
    //   },
    //   where: {
    //     hidden: false,
    //     bulk_id: null,
    //     NOT: {
    //       reported: '643945264868098049' || '669627189624307712'
    //     }
    //   },
    //   orderBy: [{created_at: "desc"}]
    // })

    let users = [];

    const uniqueUsers = [
      ...new Set(reports.map((report) => report.reported)),
    ].map((reported) => {
      return reports.find((report) => report.reported === reported);
    });
    for (const report of uniqueUsers) {
      const redisCache = await getUserCache(report.reported);

      const blacklisted = await prisma.blacklist.findFirst({
        where: {
          id: report.reported,
          active: 1,
        },
        select: {
          active: true,
        },
      });

      users.push({
        id: redisCache.id || report.reported || null,
        username: redisCache.username || "Unknown User",
        discriminator: redisCache.discriminator || "0",
        avatar: redisCache.avatar || null,
        banner: redisCache.banner || null,
        blacklisted: blacklisted?.active ? true : false,
      });
    }

    return {
      revalidate: 600,
      props: {
        reports,
        users,
      },
    };
  } catch (e) {
    console.log(e);
    return { props: { reports: [], users: false } };
  }
}
