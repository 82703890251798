import { Box, Heading, Text, Tooltip } from "@chakra-ui/react";
import { useRouter } from 'next/router'

export default function PromoComponent() {
  const router = useRouter()

  const promoteBot = () => {
    const today = new Date()
    const start = new Date(today.getFullYear(), 2, 1)
    const end = new Date(today.getFullYear(), 2, 31)
    return today >= start && today <= end
  }

  return (
    <>
      {promoteBot() && (
        <Box 
        p={5} 
        mb={3}
        color={'white'}
        rounded="md" 
        bgImage="url('https://cdn.discordapp.com/attachments/1036592514758627339/1051960101176614972/pattern_7.png')"
        shadow="2xl"
      >
        <Heading size="md">Did you know we have an official Discord bot!</Heading>
        <Text mt={1}>
          Invite the Dangercord bot to use linked roles, vote and report from the comfort of your server!&nbsp;
          <Tooltip label="Invite the bot to your server" aria-label="Invite the Dangercord bot to your server!">
            <span onClick={() => router.push("/invite")} style={{ cursor: "pointer" }}>
              <Text as="u" color="white.300">Click here to invite the bot!</Text>
            </span>
          </Tooltip>
        </Text>
      </Box>)}
    </>
  )
  
}